import omit from 'lodash/omit';
import { lazy, Suspense } from 'react';
import { useFormContext } from 'react-hook-form';
import type { FormField } from '@/components/common/form/types';

const CheckboxFormField = lazy(() => import('@/components/common/form/modules/fields/CheckboxField/CheckboxFormField').then(module => ({ default: module.CheckboxFormField })));
const ColorPickerField = lazy(() => import('@/components/common/form/modules/fields/ColorPickerField/ColorPickerField').then(module => ({ default: module.ColorPickerField })));
const DateField = lazy(() => import('@/components/common/form/modules/fields/DateField/DateField').then(module => ({ default: module.DateField })));
const DurationField = lazy(() => import('@/components/common/form/modules/fields/DurationField/DurationField').then(module => ({ default: module.DurationField })));
const GroupPickerField = lazy(() => import('@/components/common/form/modules/fields/GroupPickerField/GroupPickerField').then(module => ({ default: module.GroupPickerField })));
const RadioField = lazy(() => import('@/components/common/form/modules/fields/RadioField/RadioField').then(module => ({ default: module.RadioField })));
const RatingFormField = lazy(() => import('@/components/common/form/modules/fields/RatingFormField/RatingFormField').then(module => ({ default: module.RatingFormField })));
const SelectField = lazy(() => import('@/components/common/form/modules/fields/SelectField/SelectField').then(module => ({ default: module.SelectField })));
const TaskPickerField = lazy(() => import('@/components/common/form/modules/fields/TaskPickerField/TaskPickerField').then(module => ({ default: module.TaskPickerField })));
const TextField = lazy(() => import('@/components/common/form/modules/fields/TextField/TextField').then(module => ({ default: module.TextField })));
const TextareaField = lazy(() => import('@/components/common/form/modules/fields/TextareaField/TextareaField').then(module => ({ default: module.TextareaField })));
const ToggleField = lazy(() => import('@/components/common/form/modules/fields/ToggleField/ToggleField').then(module => ({ default: module.ToggleField })));
const UserPickerField = lazy(() => import('@/components/common/form/modules/fields/UserPickerField/UserPickerField').then(module => ({ default: module.UserPickerField })));
const CurrencyField = lazy(() => import('@/components/common/formFields/CurrencyField/CurrencyField').then(module => ({ default: module.CurrencyField })));
const UploadFormField = lazy(() => import('@/components/common/formFields/UploadFormField/UploadFormField').then(module => ({ default: module.UploadFormField })));
const PasswordField = lazy(() => import('@/components/common/form/modules/fields/PasswordField/PasswordField').then(module => ({ default: module.PasswordField })));
const HiddenField = lazy(() => import('@/components/common/form/modules/fields/HiddenField/HiddenField').then(module => ({ default: module.HiddenField })));

export const FieldsRenderer = (props: FormField) => {
  const { type, condition, ...rest } = props;
  const inputProps = omit(rest, 'defaultValue', 'value', 'Field', 'rulesValidation');

  const { setValue, getValues, getFieldState } = useFormContext();

  if (condition === 'hide') return null;

  const renderField = () => {
    const { name } = props;
    const fieldState = getFieldState(name);
    const error = fieldState.error?.message || '';

    switch (type) {
      case 'checkbox':
        return (
          <Suspense>
            <CheckboxFormField {...props} error={error} inputProps={inputProps} />
          </Suspense>
        );
      case 'toggle':
        return (
          <Suspense>
            <ToggleField {...props} error={error} inputProps={inputProps} />
          </Suspense>
        );
      case 'radio':
        return (
          <Suspense>
            <RadioField {...props} error={error} inputProps={inputProps} />
          </Suspense>
        );
      case 'duration':
        return (
          <Suspense>
            <DurationField {...props} error={error} inputProps={inputProps} value={rest.value} />
          </Suspense>
        );
      case 'textarea':
        return (
          <Suspense>
            <TextareaField {...props} error={error} inputProps={inputProps} />
          </Suspense>
        );
      case 'upload':
        return (
          <Suspense>
            <UploadFormField {...props} error={error} inputProps={inputProps} />
          </Suspense>
        );
      case 'rating':
        return (
          <Suspense>
            <RatingFormField {...props} error={error} inputProps={inputProps} />
          </Suspense>
        );
      case 'color-picker':
        return (
          <Suspense>
            <ColorPickerField {...props} error={error} />
          </Suspense>
        );
      case 'select':
        return (
          <Suspense>
            <SelectField {...props} error={error} inputProps={inputProps} />
          </Suspense>
        );
      case 'task-picker':
        return (
          <Suspense>
            <TaskPickerField {...omit(props, 'taskPickerOptions', 'options')} {...props?.taskPickerOptions} inputProps={inputProps} />
          </Suspense>
        );
      case 'user-picker':
        return (
          <Suspense>
            <UserPickerField {...props} inputProps={inputProps} />
          </Suspense>
        );
      case 'group-picker':
        return (
          <Suspense>
            <GroupPickerField {...props} inputProps={inputProps} />
          </Suspense>
        );
      case 'date':
        return (
          <Suspense>
            <DateField {...props} inputProps={inputProps} />
          </Suspense>
        );
      case 'currency':
        return (
          <Suspense>
            <CurrencyField {...props} inputProps={inputProps} />
          </Suspense>
        );
      case 'hidden':
        return (
          <Suspense>
            <HiddenField {...props} />
          </Suspense>
        );
      case 'component':
        // eslint-disable-next-line no-case-declarations
        const { Component } = props;

        if (!Component) {
          throw new Error('Component prop is required for component type');
        }

        return (
          <Suspense>
            <props.Component setValue={setValue} values={getValues()} />
          </Suspense>
        );
      case 'password':
        return (
          <Suspense>
            <PasswordField {...props} error={error} inputProps={inputProps} />
          </Suspense>
        );
      default:
        return (
          <Suspense>
            <TextField {...props} error={error} inputProps={inputProps} />
          </Suspense>
        );
    }
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {renderField()}
    </Suspense>
  );
};
