import { useFormContext } from '@/components/common/form/context/FormContext';
import { FieldsRenderer } from '@/components/common/form/modules/Field/modules/FieldsRenderer/FieldsRenderer';
import type { FormField } from '@/components/common/form/types';
import { useTranslation } from '@/hooks/useTranslation';
import { renderClasses } from '@/utils/renderClassses';
import './Field.style.css';

export const Field = (props: FormField) => {
  const { name, type, placeholder, label, groupClassName, error } = props;
  const { translate } = useTranslation();
  const { formState: { errors } } = useFormContext();

  return (
    <div className={renderClasses(groupClassName, {
      'FormField-group not-last:mb-[1.6rem] relative': true,
      [`FormField-group-${type}`]: type,
    })}
    >
      <FieldsRenderer
        {...props}
        {...typeof label === 'string' && ({
          label: translate(label),
        })}
        placeholder={placeholder && translate(placeholder)}
        error={`${errors?.[name?.replace('[]', '') as string]?.message || error || ''}`}
        Field={Field}
      />
    </div>
  );
};
